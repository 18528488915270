import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Link, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Layout from './Layout/layout';
import { ThemeProvider } from './CustomComponents/darkmode';
import ScrollToTop from './CustomComponents/ScrollToTop';
import './App.css';
import { ServicesProvider } from './CustomComponents/ServicesContext';
import { ProjectsProvider } from './CustomComponents/projectsContext';
import LoadingSpinner from './CustomComponents/Loading/LoadingSpinner';
import DelayedFallback from './CustomComponents/Loading/DelayedFallback';

// Lazy-loaded components
const Home = lazy(() => import('./MainPages/Home'));
const About = lazy(() => import('./MainPages/About'));
const Gallery = lazy(() => import('./MainPages/gallery'));
const FAQ = lazy(() => import('./MainPages/faq'));
const Contact = lazy(() => import('./MainPages/contact'));
const ServiceDetail = lazy(() => import('./CustomComponents/serviceDetail'));
const ServicesHome = lazy(() => import('./MainPages/servicesHome'));
const TermsConditions = lazy(() => import('./MainPages/Legal/TermsConditions'));
const PrivacyPolicy = lazy(() => import('./MainPages/Legal/privacypolicy'));
const Unfound = lazy(() => import('./MainPages/404'));

const GoogleAnalytics: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views using Google Analytics (gtag.js)
    const gtag = (window as any).gtag || function() {};
    gtag('config', 'G-3VQM8H2ND5', {
      page_path: location.pathname,
    });
  }, [location]);

  return null; // This component doesn't need to return any UI
};

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ServicesProvider>
        <ProjectsProvider>
          <BrowserRouter>
            <GoogleAnalytics /> {/* This component will handle Google Analytics pageview tracking */}
            <ScrollToTop />
            <Suspense fallback={<DelayedFallback><LoadingSpinner /></DelayedFallback>}>
              <Routes>
                <Route element={<Layout />}>
                  <Route path='/' element={<Home />} />
                  <Route path='/about' element={<About />} />
                  <Route path='/gallery' element={<Gallery />} />
                  <Route path='/faq' element={<FAQ />} />
                  <Route path='/services/:id' element={<ServiceDetail />} />
                  <Route path='/services' element={<ServicesHome />} />
                  <Route path='/contact' element={<Contact />} />
                  <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                  <Route path='/termsandconditions' element={<TermsConditions />} />
                  <Route path="*" element={<Unfound />} />
                </Route>
              </Routes>
            </Suspense>
            <CookieConsent
              location="bottom"
              buttonText="I understand"
              cookieName="myAwesomeCookieName"
              style={{ background: "#2C2F33", color: "#FFFFFF", padding: "16px" }} // Darker background and white text for contrast
              buttonStyle={{ color: "#FFFFFF", backgroundColor: "#4A90E2", fontSize: "13px", padding: "8px 16px", border: "none", cursor: "pointer", transition: "background-color 0.3s" }} // Blue button for better contrast
              buttonClasses="cookie-consent-button"
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
              <Link 
                to="/privacy-policy" 
                aria-label="Read our Privacy Policy to understand how we use cookies and tracking" 
                style={{ color: "#FFD700" }} // Gold link colour for good contrast against the dark background
                className="hover:text-customBlue"
              >
                Learn more
              </Link>
            </CookieConsent>
          </BrowserRouter>
        </ProjectsProvider>
      </ServicesProvider>
    </ThemeProvider>
  );
}

export default App;
